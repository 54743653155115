
import React from "react";
import {Link} from "gatsby"
import { StaticImage } from "gatsby-plugin-image";
import { Card } from "react-bootstrap";
import AccordionItem from "../components/accordion-item";
import BlogFeed from "../components/blog-feed";
import CTA from "../components/cta";
import Layout from "../components/layout";
import Seo from "../components/seo"
import NeedAQuote from "../components/need-a-quote";
import Quote from "../components/quote";
import patternHex from "../images/backrounds/pattern-hex-white.jpg";

const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Zendesk Efficiency"
        description="729 Solutions is a proud partner of Zendesk and can review your instance to ensure it is structured for optimal efficiency."
      />
      <section>
        <div
          className="hero mb-5"
          style={{
            backgroundImage: `url(${patternHex})`,
          }}
        >
          <div className="hero-title bg-dark-transparent">
            <div className="hero-image">
              <StaticImage
                src="../images/clients/zendesk.png"
                alt="Zendesk Implementation Premier Partner"
                layout="constrained"
                style={{ maxWidth: "200px"}}
              />
            </div>
            <h1 className="text-white">
              Structure your Zendesk instance efficiently and let it do the hard
              work for you!
            </h1>
            <p className="text-white">
              Whether you’re brand new to Zendesk, or you’ve been at it for a
              while now – we can likely agree that there is always room for
              improvement.
            </p>
            <CTA
              href="/contact-us/"
              text="Get Help Making the Most of Zendesk"
              className="btn btn-primary"
            />
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Start Strong or get back on track</h2>
              <div className="row">
                <div className="col-12 col-lg-6 mb-3 mb-lg-0">
                  <p>
                    If you’re starting from scratch, make sure you get off on
                    the right foot by structuring content for optimal customer
                    deflection and proper support ticket routing so tickets get
                    to the right agent, for example.
                  </p>
                  <p>
                    If you’ve been at it for a while, then things have likely
                    gotten messy as your support team has turned over and
                    customer requests have changed.
                  </p>
                </div>
                <div className="col-12 col-lg-6">
                  <p>
                    Either way – you’re not alone! 729 offers Efficiency Checks
                    to review the health and optimization of your customer
                    self-service and support team workflows. Our Certified
                    Zendesk Admins will help you identify areas you improve
                    agent efficiency, speed up ticket handling, manage
                    skills-based routing, and minimize agents required due to
                    improved customer deflection and increased customer
                    satisfaction.
                  </p>
                </div>
                <div className="col-12 my-5">
                  <div className="text-center">
                    <CTA
                      href="/contact-us/"
                      text="Tell Us About Your Project"
                      className="btn btn-primary"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-6">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <h2>Our Zendesk Efficiency Audits Include…</h2>
              <div className="mb-5">
                <ul className="text-primary">
                  <li>
                    <span className="text-dark">
                      An initial assessment of your known pain points and
                      pie-in-the-sky wishlist items
                    </span>
                  </li>
                  <li>
                    <span className="text-dark">
                      An in-depth analysis of your tickets, installed
                      applications, triggers, automations, custom fields, users,
                      groups, organizations, ticket forms, and macros
                    </span>
                  </li>
                  <li>
                    <span className="text-dark">
                      Our recommendations based on years of experience working
                      with Zendesk as a Master Partner
                    </span>
                  </li>
                </ul>
              </div>
              <div>
                <Card className="card custom-cards card-border-left mb-5 py-4">
                  <Card.Body>
                    <Card.Title as="h2" className="text-left">
                      729 OFFERS CUSTOMIZED EFFICIENCY AUDITS TO MEET YOUR
                      SPECIFIC NEEDS!
                    </Card.Title>
                    <div className="text-left">
                      <CTA
                        href="/contact-us/"
                        text="learn more"
                        className="mt-5 btn btn-primary"
                      />
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <h2>Zendesk Functionality Review</h2>
              <div className="mb-5">
                <h3>Zendesk Explore</h3>
                <div className="">
                  <ul className="text-primary">
                    <li>
                      <span className="text-dark">
                        Are your report dashboards giving you the information
                        you need?
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Are your report dashboards giving you the information
                        you need?
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        How much visibility do you have into team and agent
                        performance?
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mb-5">
                <h3>User Experience</h3>
                <div className="">
                  <ul className="text-primary">
                    <li>
                      <span className="text-dark">
                        How can you improve your customer’s journey?
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        What integrations can you implement to make your
                        instance more efficient for you and your customer?
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        How can you customize your instance for a specific user
                        persona?
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <h3>Other Zendesk Products</h3>
                <div className="">
                  <ul className="text-primary">
                    <li>
                      <span className="text-dark">
                        Are you using{" "}
                        <Link to="/zendesk-leads-live-chat-services/">Chat</Link>,{" "}
                        <Link to="/zendesk-talk-your-phone-system/">Talk</Link>,
                        and Gather to their fullest potential?
                      </span>
                    </li>
                    <li>
                      <span className="text-dark">
                        Are your other business tools like Salesforce integrated?
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Recent Zendesk Customers</h2>
            </div>
            <div className="col-12 col-lg-4">
              <div className="p-6">
                <StaticImage
                  src="../images/clients/zendesk-customers/genomix.png"
                  alt="10x Genomics"
                />
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="p-6">
                <StaticImage
                  src="../images/clients/zendesk-customers/accureg.png"
                  alt="Accureg"
                />
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="p-6">
                <StaticImage
                  src="../images/clients/zendesk-customers/bradley.png"
                  alt="Bradley Real Estate"
                />
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="p-6">
                <StaticImage
                  src="../images/clients/zendesk-customers/kidkraft.png"
                  alt="KidKraft"
                />
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="p-6">
                <StaticImage
                  src="../images/clients/zendesk-customers/learnondemand.png"
                  alt="Learn on demand Systems"
                />
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="p-6">
                <StaticImage
                  src="../images/clients/zendesk-customers/singularity.png"
                  alt="Singularity University"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="my-6">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <Quote
                quote="The structured reporting was great. It really gave us what we needed."
                name="Daniel Davis"
                company="Namecheap"
              />
            </div>
          </div>
        </div>
      </section>
      <NeedAQuote
        title="Ready to optimize your Zendesk instance?"
        text="Stop fumbling through oodles of support requests and set your support team up for success now!  Reach out today and initiate a review of your instance."
        buttonText="Schedule a Consultation"
      />
      <section className="mt-6">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>FAQ’s On Zendesk Efficiency</h2>
            </div>
            <div className="col-12">
              <div className="accordion-729">
                <AccordionItem
                  id="0"
                  title="Will an efficiency audit make my support team more
                  efficient?"
                  text="We cannot guarantee that an Efficiency Audit will make
                  your support team more efficient, but what we can
                  guarantee is that you’re providing the right tools,
                  assets, and resources for your support team to do their
                  jobs quickly and accurately."
                />
                <AccordionItem
                  id="1"
                  title="Will an efficiency audit review my Zendesk Sunshine"
                  text={[
                    "Yes! Our ",
                    <Link to="/zendesk-efficiency-audits/">Efficiency Audit</Link>,
                    " will cover every Zendesk Product you are using and give it a throughout review from top to bottom.",
                  ]}
                />
                <AccordionItem
                  id="2"
                  title="Can 729 Solutions review our agent skills-based routing?"
                  text="Absolutely! We’ll make note of any workflows you have
                      currently set up, ensure they are all functioning as
                      intended, make recommendations for improvements, and even
                      help you implement changes upon request."
                />
                <AccordionItem
                  id="3"
                  title="Can you help us integrate improved agent solutions communicating customer context?"
                  text="We can help you solve any Zendesk problems under the sun. In fact – the more complex the better! We like a good challenge and would love to help you improve your Knowledge Base and integrations to resolve this very unique and specific problem."
                />
                <AccordionItem
                  id="4"
                  title="Will an efficiency audit identify ways to help deflect customer requests?"
                  text="Yes – we’ll review your current automations as well as your content structure to ensure your self-service component is as optimized as possible, aiding in users’ ease of search to resolve support requests without support agent intervention."
                />
                <AccordionItem
                  id="5"
                  title="Does the efficiency audit ensure tickets are going to the right agent?"
                  text="It certainly can! If this is a pain point of yours, just let us know. We’ll be sure to review your routing and map out a plan for improvement if needed."
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="my-6">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bg-gray-200 px-3 px-md-6 py-6 text-center">
                <h2>
                  Still have questions about optimizing your Zendesk
                  Instance?
                </h2>
                <CTA
                  href="/contact-us/"
                  text="Let's talk"
                  className="btn btn-primary"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <BlogFeed />
    </Layout>
  );
};

export default Index;
